<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.UY.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <pz-direction
        v-model="formulario2.direccion"
        ask-postal-code
      />
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="tipo_vivienda"
            :label="$t('forms_flujo.form2_persona.UY.property_type')"
            :error="errors.get('tipo_vivienda')"
          >
            <el-select
              v-model="formulario2.tipo_vivienda"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.property_type')"
            >
              <el-option
                v-for="item in properties"
                :key="item.id"
                :label="item.tipo"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.UY.property_type')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email"
            :label="$t('forms_flujo.form2_persona.UY.email')"
            :error="errors.get('email')"
          >
            <el-input
              v-model="formulario2.email"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.email')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_fijo"
            :label="$t('forms_flujo.form2_persona.UY.phone')"
            :error="errors.get('telefono_fijo')"
          >
            <el-input
              v-model="formulario2.telefono_fijo"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.phone')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="estudio"
            :label="$t('forms_flujo.form2_persona.UY.studies')"
            :error="errors.get('estudio')"
          >
            <el-select
              v-model="formulario2.estudio"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.studies')"
            >
              <el-option
                v-for="item in studies"
                :key="item.id"
                :label="item.estudio"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            v-if="!(jubilado==1)"
            prop="relacion_laboral"
            :label="$t('forms_flujo.form2_persona.UY.employee_type')"
            :error="errors.get('relacion_laboral')"
          >
            <el-select
              v-model="formulario2.relacion_laboral"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.employee_type')"
            >
              <el-option
                v-for="item in employment_relations"
                :key="item.id"
                :label="item.relacion"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            v-if="!(jubilado==1)"
            prop="telefono_empresa"
            :label="$t('forms_flujo.form2_persona.UY.company_phone')"
            :error="errors.get('telefono_empresa')"
          >
            <el-input
              v-model="formulario2.telefono_empresa"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.company_phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="comoseentero"
            :label="$t('forms_flujo.form2_persona.UY.how_find_out')"
            :error="errors.get('comoseentero')"
          >
            <el-select
              v-model="formulario2.comoseentero"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.how_find_out')"
            >
              <el-option
                v-for="item in formas_de_enterarse"
                :key="item.id"
                :label="item.forma"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.link_facebook"
            :label="$t('forms_flujo.form2_persona.UY.link_facebook')"
            :error="errors.get('info_extra.link_facebook')"
          >
            <el-input
              v-model="formulario2.info_extra.link_facebook"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.link_facebook')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.telefono_sponsor"
            :label="$t('forms_flujo.form2_persona.UY.telefono_sponsor')"
            :error="errors.get('info_extra.telefono_sponsor')"
          >
            <el-input
              v-model="formulario2.info_extra.telefono_sponsor"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.telefono_sponsor')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-col :span="24">
        <hr class="pz-dotted">
      </el-col>
      <el-row
        :gutter="20"
        class="pz-large-margin"
      >
        <el-col
          :span="24"
          class="row justify-content-center"
        >
          <b class="pz-font-size-lg">
            {{ $t('forms_flujo.form2_persona.UY.contact.title') }}
          </b>
        </el-col>
        <el-col
          :span="24"
          class="row justify-content-center"
        >
          <label>
            {{ $t('forms_flujo.form2_persona.UY.contact.sub_title') }}
          </label>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="persona_contacto"
            :label="$t('forms_flujo.form2_persona.UY.contact.full_name')"
            :error="errors.get('persona_contacto')"
          >
            <el-input
              v-model="formulario2.persona_contacto"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.contact.full_name')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_persona_contacto"
            :label="$t('forms_flujo.form2_persona.UY.contact.phone')"
            :error="errors.get('telefono_persona_contacto')"
          >
            <el-input
              v-model="formulario2.telefono_persona_contacto"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.UY.contact.phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<script>
    import {required, type} from "@/commons/utils/Rules";
    import {
        getDepartamentos,
        getEstudios,
        getFormasDeEnterarse,
        getRelacionesLaborales,
        getTipoViviendas
    } from "@/routes/api/resources";
    import baseform2 from '../baseForm2'
    import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
    import PzDirection from "@/commons/forms/Fields/PzDirection";
    export default {
        name: 'PzPersonFormExtNetPC',
        components: {PzFormFlujo,PzDirection},
        mixins: [baseform2],
        data() {
            var check_Fecha = (rule, value, callback) => {
                if (!this.jubilado && !value) {
                    return callback(new Error(this.$t('forms_flujo.form2_persona.UY.mandatory_field')));
                } else {
                    return callback();
                }
            };
            return {
                properties: [],
                provincias: [],
                employment_relations: [],
                studies: [],
                formas_de_enterarse: [],
                formulario2: {
                    tipo_vivienda: '',
                    direccion: {},
                    provincia: '',
                    telefono_fijo: '',
                    email: '',
                    telefono_empresa: '',
                    estudio: '',
                    relacion_laboral: '',
                    persona_contacto: '',
                    telefono_persona_contacto: '',
                    comoseentero: '',
                    url_trackeo: '',
                    ref_empresa: '',
                    tipo_documento: 1,
                    info_extra: {
                        link_facebook: '',
                        telefono_sponsor: '',

                    },
                }
                ,
                disable: true,
                rules_formulario2:
                    {
                        tipo_vivienda: [
                            required('')
                        ],
                        direccion: [
                            required('')
                        ],
                        provincia: [
                            required('')
                        ],
                        telefono_fijo: [
                            required('')
                        ],
                        email: [
                            type({field: '', type: 'email'})
                        ],
                        telefono_empresa: [
                            {
                                validator: check_Fecha, trigger: 'blur'
                            }
                        ],
                        estudio: [
                            required('')
                        ],
                        relacion_laboral: [
                            {
                                validator: check_Fecha, trigger: 'blur'
                            }
                        ],
                        persona_contacto: [
                            required('')
                        ],
                        telefono_persona_contacto: [
                            required('')
                        ],
                        comoseentero: [
                            required('')
                        ],
                    },
                pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                },
            };
        },
        created: function () {
        },
        beforeMount() {
            var viv = getTipoViviendas().then((res) => {
                this.properties = res.data;
            }).catch(() => {
                this.properties = [];
            });
            var dep = getDepartamentos().then((res) => {
                this.provincias = res.data;
            }).catch(() => {
                this.provincias = [];
            });
            getEstudios().then((res) => {
                this.studies = res.data;
            }).catch(() => {
                this.studies = [];
            });
            var re = getRelacionesLaborales().then((res) => {
                this.employment_relations = res.data;
            }).catch(() => {
                this.employment_relations = [];
            });
            var form = getFormasDeEnterarse().then((res) => {
                this.formas_de_enterarse = res.data;
            }).catch(() => {
                this.formas_de_enterarse = [];
            });

            Promise.all([viv, dep, re, form]).then(() => {

            }).finally(() => {this.$store.dispatch('loader/down',{trigger:this.$options.name})});
        },
        methods: {},
    }
</script>

